const initialState = {
  countCart: 0,
  storeFavCount: 0,
  loginModal: false,
  headerLoginModal: false,
  FooterLoginModal: false,
  loginData: {},
  diamondPageChnages: false,
  diamondNumber: "",
  HeaderLogoData: [],
  DefaultBillingAddress: [],
  activeDIYtabs: [],
  jeweleryDIYName: "",
  jeweleryDIYimage: "",
  diamondDIYName: "",
  diamondDIYimage: "",
  storeEntityId: {},
  isJewelDIY: false,
  isDiamoDIY: false,
  storeCurrencyData: [],
  storeCurrency: "",
  logoDetail: [],
  sliderAlignment: "",
  activeDiamondTabs: '',
  addedDiamondData: {},
  productVariantId: "",
  zurahDiamond: {},
  addedJewelleryProduct: {},
  addedRingAndDiamond: {},
  diamondParamsObj: {},
  addedJewelRing: {},
  editDiamondValue: "",
  currentShapeName: [],
  orderDataArray: [],
  engravingObj: {},
  productNameList: [],
  isSelected: true,
  isVerified: 0,
};

const Reducer = (state = initialState, action) => {

  switch (action.type) {
    case "countCart": {
      return {
        ...state,
        countCart: action.countCart,
      };
    }

    case "loginModal": {
      return {
        ...state,
        loginModal: action.loginModal,
      };
    }
    case "sliderAlignment": {
      return {
        ...state,
        sliderAlignment: action.sliderAlignment,
      };
    }

    case "headerLoginModal": {
      return {
        ...state,
        headerLoginModal: action.headerLoginModal,
      };
    }

    case "FooterLoginModal": {
      return {
        ...state,
        FooterLoginModal: action.FooterLoginModal,
      };
    }
    case "logoDetail": {
      return {
        ...state,
        logoDetail: action.logoDetail,
      };
    }

    case "loginData": {
      return {
        ...state,
        loginData: action.loginData,
      };
    }

    case "HeaderLogoData": {
      return {
        ...state,
        HeaderLogoData: action.HeaderLogoData,
      };
    }

    case "DefaultBillingAddress": {
      return {
        ...state,
        DefaultBillingAddress: action.DefaultBillingAddress,
      };
    }

    case "activeDIYtabs": {
      return {
        ...state,
        activeDIYtabs: action.activeDIYtabs,
      };
    }

    case "jeweleryDIYName": {
      return {
        ...state,
        jeweleryDIYName: action.jeweleryDIYName,
      };
    }

    case "jeweleryDIYimage": {
      return {
        ...state,
        jeweleryDIYimage: action.jeweleryDIYimage,
      };
    }

    case "diamondDIYName": {
      return {
        ...state,
        diamondDIYName: action.diamondDIYName,
      };
    }

    case "diamondDIYimage": {
      return {
        ...state,
        diamondDIYimage: action.diamondDIYimage,
      };
    }

    case "diamondPageChnages": {
      state.diamondPageChnages = action.diamondPageChnages;
      return {
        ...state,
        diamondPageChnages: state.diamondPageChnages,
      };
    }

    case "diamondNumber": {
      state.diamondNumber = action.diamondNumber;
      return {
        ...state,
        diamondNumber: state.diamondNumber,
      };
    }

    case "storeCurrencyData": {
      state.storeCurrencyData = action.storeCurrencyData;
      return {
        ...state,
        storeCurrencyData: state.storeCurrencyData,
      };
    }


    case "storeCurrency": {
      state.storeCurrency = action.storeCurrency;
      return {
        ...state,
        storeCurrency: state.storeCurrency,
      };
    }

    case "isJewelDIY": {
      state.isJewelDIY = action.isJewelDIY;
      return {
        ...state,
        isJewelDIY: state.isJewelDIY,
      };
    }

    case "isDiamoDIY": {
      state.isDiamoDIY = action.isDiamoDIY;
      return {
        ...state,
        isDiamoDIY: state.isDiamoDIY,
      };
    }

    case "storeEntityId": {
      state.storeEntityId = action.storeEntityId;
      return {
        ...state,
        storeEntityId: state.storeEntityId,
      };
    }
    case "storeFavCount": {
      state.storeFavCount = action.storeFavCount;
      return {
        ...state,
        storeFavCount: state.storeFavCount,
      };
    }

    case "ACTIVEDIAMONDTABS": {
      return {
        ...state,
        activeDiamondTabs: action.activeDiamondTabs,
      };
    }

    case "ADD_JEWELLERY_PRODUCT": {
      return {
        ...state,
        addedJewelleryProduct: action.payload,
      };
    }

    case "DIAMOND_SELECT": {
      return {
        ...state,
        zurahDiamond: action.payload,
      };
    }

    case "ADDTORING": {
      return {
        ...state,
        addedDiamondData: action.diamond_data,
      };
    }

    case "SELECT_THIS_RING": {
      return {
        ...state,
        addedRingAndDiamond: action.payload,
      };
    }

    case "SELECT_DIAMOND_PARAMS": {
      return {
        ...state,
        diamondParamsObj: action.payload,
      };
    }

    case "SELECT_JEWEL_RING": {
      return {
        ...state,
        addedJewelRing: action.payload,
      };
    }

    case "EDIT_RING_DIAMOND": {
      return {
        ...state,
        editDiamondValue: action.payload,
      };
    }

    case "SET_SHAPE_NAME": {
      return {
        ...state,
        currentShapeName: action.payload,
      };
    }

    case "SET_ORDER": {
      return {
        ...state,
        orderDataArray: action.payload,
      };
    }

    case "ADD_ENGRAVING": {
      return {
        ...state,
        engravingObj: action.payload,
      };
    }

    case "ADD_FILTER": {
      return {
        ...state,
        productNameList: action.payload,
      };
    }

    case "UN_SELECT_FILTER": {
      return {
        ...state,
        isSelected: action.payload,
      };
    }

    case "VERIFICATION_STATUS": {
      return {
        ...state,
        isVerified: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default Reducer;
